<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box  px-6">
				<h1 class="tit_page mb-2">계약 특이사항</h1>
				<p class="txt_body1 mb-4 ">
					<span class="txt_warn fln"
						>계약서를 전송받을 수 있는 링크 및 계약서를 타인에게 전송할 경우
						계약의 전권을 위임한 것으로 간주 되니 유의 바랍니다.
					</span>
				</p>
			</div>

			<div class="txt_box  px-6">
				<h3 class="tit_sub">프로모션, 결합할인 특약 위약금</h3>
				<p class="txt_body1 mb-4 ">
					약정 기간 내 해약 시 기본 위약금 외에 (렌탈료 추가 할인
					누적분)할인금액 추가
				</p>
			</div>
			<div class="txt_box  px-6">
				<h3 class="tit_sub">소모품비</h3>
				<p class="txt_body1 mb-4 ">
					14일 이내 반환 시, 청구되는 비용
				</p>
			</div>

			<div class="txt_box  px-6">
				<h3 class="tit_sub">회수비</h3>
				<p class="txt_body1 mb-4 ">
					계약기간 내 반환 시, 청구되는 추가 비용 (단, 14일 이내 제외)
				</p>
			</div>

			<div class="txt_box  px-6">
				<ul class="box_ex_01">
					<li>
						계약 내용 외 판매인과의 어떠한 합의 또는 특약은 정책상 금지되어
						있으며 교부된 계약서에 기재되어 있지 않은 사항은 당사에 주장할 수
						없습니다
					</li>
					<li>
						자세한 내용(특이사항, 프로모션 할인 적용가 등)은 계약서를 참고
						바랍니다.
					</li>
					<li>인증 시 개인정보이용과 신용정보조회에 동의하게 됩니다.</li>
				</ul>
			</div>

			<div class=" txt_element1 b px-6 mb-6 tac">
				코웨이㈜를 이용해 주셔서 감사합니다.
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	components: {},
	props: {},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
